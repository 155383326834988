$colorBullet: (#E67E22,1)(#3498DB,2)(#9B59B6,3)(#2ECC71,4);

#chatter{
  .left-column .nav-pills > li {
        list-style-type: square;
        list-style-position: inside;
        line-height: 1;
        margin-left: 30px;
    &::marker {
    font-size: 160%;
    }
    & > a {
        padding: 0px !important;
        margin-left: -10px;
    }
  }

  img{
    &.avatar{
      max-height: 30px;
      max-width: 30px;
      border-radius: 50%;
    }
  }

  .list{
    article{
      padding: .5rem;
      margin-bottom: .5rem;
      &:nth-child(even) {
        background-color: #f2f2f2;
      }

      .chatter_middle_details{
        font-size: 90%;
        font-style: italic;
      }
    }
  }
}

@each $colorValue,$number in $colorBullet {
  .color--marker li:nth-child(#{$number})::marker {
    color: $colorValue;
  }
}

#chatter_hero_dimmer{
    background-image: url(https://devdojo.com/assets/img/wood-pattern.svg);
    width: 100%; 
    min-height: 150px; 
    position: relative; 
    background-size: cover; 
    background-position: center center; 
    text-align: center;
    h1 {
        color: #fff;
    }
    p {
        color: #fff;
    }
}
@media (max-width: 768px) {
    .chatter_middle_details img {
        width: 60px;
    }
}

#container-fluid .container{
    @media screen and (min-width: 768px) and (max-width: 991px) {
        max-width: 820px !important;
    }
}

