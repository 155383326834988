/*! HERO - MAIN */
#hero {
  height: 550px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  box-shadow: inset 0 0 0 100vmax rgba(3, 158, 89, 0.8);
  @media (max-width: 576px) {
      margin-top: 0;
  }
}

/*! END HERO */


@media (max-width: 768px) {
    #hero{
        height: 450px;
    }
}
