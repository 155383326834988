body.show_event, #container-transfer{
  &.nh{
    #navbar-vivetix, footer .footer, footer .sub-footer{
      display: none!important;
    }
  }

  header.main{
    padding: 20px 10px;
    position:  relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom:0;
      z-index: -1;
      display: block;
      -webkit-filter: blur(5px);
      -moz-filter: blur(5px);
      -o-filter: blur(5px);
      -ms-filter: blur(5px);
      filter: blur(5px);
      background-repeat: no-repeat;
      background-size: cover;
    }
    .container{
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 10px;
    }
  }

}


  .carousel {
    .carousel-control-next,
    .carousel-control-prev /*, .carousel-indicators */ {
        filter: invert(100%);
    }

    .carousel-inner img {
      width: 100%;
      margin: auto;
      height:auto;
    }

    @media (max-width: 849px) {
      .carousel-inner img {
        max-height: 350px;
        width: auto;
        height: auto;
      }
    }
    @media (min-width: 850px) {
      .carousel {
        max-height: 450px;
      }

      .carousel-inner img {
        max-height: 450px;
        width: auto;
        height: auto;
      }
    }
  }

  #leermaslink{
      margin: auto;
      width: 100%;
      border: 1px solid #039e59;
      padding: 7px;
  }

    #descriptionsEvent{
        margin-top: 5px;
    }

    #descriptionsEvent img, #descriptionsEvent video{
        max-width: 100%;
        height:auto;
        margin: auto;
        display: block;
    }

    #descriptionsEvent .wrapper{
        max-width: 600px;
        margin: auto;
    }

    #descriptionsEvent  .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
    }

    #descriptionsEvent  .embed-container iframe {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .delete_paddings{
        padding:0 !important;
    }

body.widget {
  padding-top: 0;
}

.close {
  opacity: 1 !important;
}

.carousel-control {
  &.left, &.right {
    background-image: none;
  }
}

.carousel-indicators li {
  border-color: #ccc;
}


.modal-content {
  border: none;
  box-shadow: none;
}

body.widget .info {
  display: none;
}

#list-event h4{
  margin-top: 17px;
  font-weight: bold;
}

main {
  #load-less, #load-more{
    display: block;
    margin: auto;
    font-weight: bold;
  }
  #load-less{
    margin-bottom: 5px;
  }
  #load-more{
    margin-top: 5px;
  }

  .show_check{
    margin: 10px auto;
  }
}

.event {
  margin-top: 10px;
  border-bottom: 1px solid #eee;
  padding: 5px;
  .date-time{
    line-height: 3;
    strong{
      color: $link-color;
      margin-right: 15px;
    }
    a{
        line-height: 1.1;
        display: block;
    }
  }

  .btn-success{
    small{
      font-size:90%;
      display: block;
    }
  }

  @media screen and ( max-width: 650px ) {
    .date-time{
      strong{
        display: block;
      }
    }
  }

  .ticket-window{
    margin-left: 10px;
  }
  > .date {
    text-align: center;
    font-size: 120%;

    .day {
      font-size: 160%;
      color: #111;
    }

    .month {
      color: #111;
    }

    &.several {
      font-size: 100%;
      color: #111;
    }
  }

  .date.several {
    .month, .day {
      display: inline-block;
    }
  }

  > .date.several {
    .day {
      font-size: 100%;
    }

    hr {
      margin: 0;
      margin-top: 15px;
      border: none;

      &::before {
        content: "al";
        display: block;
        position: absolute;
        top: 18px;
        left: 0;
        width: 100%;
        color: #333;
      }
    }
  }

  &::after {
    clear: both;
    content: "\00a0";
    font-size: 1px;
  }
}

.hour {
  color: #777;
  font-weight: bold;

  button {
    padding-left: 0px;
    font-weight: bold;
  }
}

.address {
  /*font-weight: bold;*/
  color: #777;
}

.event .btn {
  margin: 5px auto;
}

#load-more, #load-less {
  text-align: center;
  padding: 13px;
}

#load-more button, #load-less button {
  padding: 10px 70px;
}

.photo img {
  margin: 0 auto;
}

header::before {
  content: " ";
  clear: both;
}

.short {
  overflow: hidden;
  transition: height 0.5s ease-in;
}

.long {
  max-height: none;
}

.more {
  display: block;
}

.less {
  display: none;
}

.long {
  .more {
    display: none;
  }

  .less {
    display: block;
  }
}

#descriptionsEvent {
  ul, ol {
    list-style-type: initial !important;
    margin-left: 20px;
  }
}

.shadow {
  position: relative;
  display: block;
  overflow: hidden;
  box-shadow: 0 10px 10px -8px rgba(0, 0, 0, 0.15) !important;

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
    width: 100%;
    height: 5em;
  }
}

h1 {
  font-size: 30px;
  margin-top: 0px;
}

.v-center_container {
  position: relative;
  width: 100%;
  padding-bottom: 80%;
}

.v-center img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 350px;
}

button.list-group-item {
  text-align: center;
}

#buy-dialogue {
  background: #fff;

  &.modal-open {
    overflow: hidden;
  }
}

@media (max-width: 450px) {
  .event .btn {
    margin: 1px auto;
  }

  body.widget a.link {
    display: block;
    padding: 3px;
    font-size: 110%;
  }
}

#buy-dialogue .selectors {
  padding: 15px;
}

input.option-quantity {
  padding: 6px;
}

body.widget {
  padding-top: 0;
}

.options-box header {
  margin-bottom: 3rem;
}

.close {
  opacity: 1 !important;
}

.options-box {
  padding: 5px;

  h5 {
    text-align: center;
  }
}

.questions-div .options-box {
  padding: 20px;
}

.ticketOpt {
  min-height: 40px;
}

.trow {
  margin-bottom: 10px;
}

[data-close="nested"] {
  float: left;
  margin-right: 5px;
}

.trow > div.col-sm-3 {
  padding-left: 10px;
}

#close-selector {
  display: block;
  float: left;
}

.modal.nested {
  background: #fff;

  .modal-content {
    box-shadow: none;
    border: none;
  }
}

.btn-grey {
  background-color: #ddd;
}

.grouping {
  display: block;
  text-align: center;
  margin: 10px auto;
  font-size: 20px;
  color: #039e59;
  border: 1px solid #039e59;
  border-radius: 3px;
  background-color: #fff;
  appearance: button;
  -webkit-appearance: button;
}

@media (min-width: 769px) {
  body.widget #event-options {
    margin: auto;
    max-width: 500px;
    width: auto;
    float: none;
  }
}

@media (max-width: 1200px) {
  .selectors {
    display: table;
    width: 100%;
    margin: auto;
    table-layout: fixed;
    padding: 12px;
  }
}

@media (max-width: 400px) {
  .input-group-btn .btn {
    padding: 6px 10px;
  }
}

#options-box {
  display: block;
}

#fixed-footer {
  transition: all .3s ease-in-out;
}

.close.left {
  float: left;
}

#form-payment {
  padding-bottom: 2rem;
}

#fees {
  font-size: 13px;

  &.collapse.in {
    display: inline-block;
  }
}

.btn-default-m {
  font-size: 16px;
  padding: 11px 40px;
}

@media (max-width: 768px) {
  #options-title {
    text-align: center;
    font-weight: bold;
    font-size: 15px;
  }

  .btn-success-m {
    font-size: 15px;
    padding: 8px 15px;
  }

  #close-selector {
    font-size: 35px;
    opacity: .9;
    margin-top: 5px;
  }

  #buyTickets.opened-option {
    #options-box {
      display: block;
    }

    #event-info, #panel-user, #panel-admin {
      display: none;
    }
  }

  #options-box .selectors {
    max-width: 450px;
    margin: auto;
  }

  body.widget #options-title {
    display: none;
  }
}

.short.long {
  box-shadow: 0 10px 10px -8px rgba(0, 0, 0, 0.15) !important;
}

.separatorGuion {
  display: none;
  @media screen and (min-width: 519px) and (max-width: 991px) {
    display: inline;
  }
}

#event_status_f, #all_users_s, #participation_status_f {
  button:hover, button:active, button.active {
    color: #039e59;
  } 
}

.errorInputs {
  border: 2px solid #dc3545;
}