$facebook-blue: #3b5998;
$gray-bg: #f2f2f2;

#login-form{
  .rounded{
    background: $gray-bg;
  }

  #email-login-normal{
    background: $gray-bg;
    font-size: 120%;
    border: none;
    color: #777;
    width: auto;
    text-align: center;
    display: block;
    margin: auto;
    font-weight: bold;
  }

  #btnFbLoginreg{
    color: #fff;
    border-color: darken($facebook-blue, 10%);
    background:  $facebook-blue;
  }
}