#header_list{
  /*! FILTERS CITIES */
  .dropdown-menu{
    li.dropdown-item{
      padding: 0;
      button{
        text-decoration: none;
        display: block;
        width:100%;
      }

      &.active{
        button{
          color: #fff;
        }
      }
    }
  }
}

/*!  EVENT LIST - STYLE CARDS */
.type-list .box .card {
  min-width: 34rem;
  height: 13rem;
  margin-left: 5px !important;
  margin-right: 5px !important;


    a {
        max-width: 12rem;
        margin: auto 0;
        img {
            min-width: 9rem;
            max-height: 13rem;
          }
    }
    .card-body {
        max-width: 350px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    @media (max-width: $x-small) {
          max-width: 21rem;
          height: 13rem;
          min-width: 15rem;
        
        .card-body {
          max-width: 160px;
        }
        a {
          max-width: 9rem;
          margin: auto 0;
        }
    }
    @media screen and (min-width: 361px) and (max-width: $small-sm) {
            max-width: 33rem;
            height: 13rem;
            min-width: 21rem;  
           a {
            max-width: 9rem;
            margin: auto 0;
          }
           .card-body {
            max-width: 350px;
          }
    }
    @media screen and (min-width: 577px) and (max-width: $medium-md) { 
            max-width: 44rem;
            height: 13rem;
            min-width: 34rem;
         
            .card-body {
            max-width: 350px;
          }
    }
    @media screen and (min-width: 769px) and (max-width: 991px) {
        
          max-width: 50rem;
          height: 13rem;
          min-width: 46rem;
        
         .card-body {
          max-width: 478px;
        }
      }
      @media screen and (min-width: $large-lg) and (max-width: 1199px) { 
        
          max-width: 65rem;
          height: 13rem;
          min-width: 59rem;
        
         .card-body {
          max-width: 720px;
        }
       }
  }
  
  .type-grid .box .card {
    width: 16rem;
    height: 19rem;
     a img {
      height: 10rem;
      object-fit: contain;
      padding-top: 15px;
    }
    .card-body {
      overflow: hidden;
    }
    @media screen and (min-width: 361px) and (max-width: $small-sm) {
            width: 19rem;
    }
  }
  
  /*! FILTERS */
  /*! FILTERS LIST OR GRID */
  .view_type .btn-group input[type="radio"]{
        &:checked + label > svg, &:hover + label > svg {
            fill: #fff;
        }
  }

  /*! FILTERS CATEGORIES */
  #category_div li{
      label {
        cursor: pointer;
      }
  } 


  @media (max-width: 598px) {
    #load-less button {
      margin-bottom: 15px;
    }
  }

.box {
    margin-left: 1rem;
    margin-right: 1rem;
}