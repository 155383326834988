.no-bold{
  font-weight: normal;
}
  
  .options_row, .sessions_row, .questions_row {
    padding: 5px 0;
  }
  
  .sessions_row .input-group{
    padding:2px;
  }
  
  .sessions_row  .second::before{
    clear: both;
    content: " ";
    display: block;
  }
  
  .options_row, .sessions_row{
    margin-bottom: 5px;
    border-bottom: 1px solid #ddd;
  }
  
  .options_row .remove_row button,.questions_row .remove_row button {
    margin-top: 5px;
  }
  
  .options_row .second{
    margin-top: 15px;
  }
  
  .options_row hr{
    clear: both;
    margin: 5px;
    border:none;
  }
  
  .options_row label, .products_row label{
    font-weight: normal;
    width: 100%;
  }
  
  textarea[name=custom_message] {
    height: 55px;
  }

  .price + .help-block{
    color:#aaa;
  }

  select.simples{
    border:none;
    border-bottom: 1px #ccc solid;
    -moz-appearance: window;
    -webkit-appearance: none;
    padding: 10px;
    background: url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat  95% center #fff;
  }
  
  button.prices{
    background: url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat  95% center #fff;
    padding-right: 25px;
  }
  
  .g-recaptcha > div{
    margin: auto;
  }

input + .form-text .text-danger{
  display: none;
}

input:focus + .form-text .text-danger{
  font-weight: bold;
  display: inline-block;
}

.striped{
  & > .row:nth-child(even) {
    background: #f6f6f6;
  }
}