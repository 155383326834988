/* CDN , */
// Import custom Bootstrap variables
@import "bs-variables";
// Import Bootstrap for Sass

// Core variables and mixins
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// Core CSS
// @import "bootstrap/scss/grid";

// @import "bootstrap/scss/scaffolding";
// @import "bootstrap/scss/type";
// @import "bootstrap/scss/code";

// @import "bootstrap/scss/tables";
// @import "bootstrap/scss/buttons";

// // Components
// @import "bootstrap/scss/component-animations";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
// @import "bootstrap/scss/input-groups";

// @import "bootstrap/scss/breadcrumbs";
@import "bootstrap/scss/pagination";
// @import "bootstrap/scss/pager";
// @import "bootstrap/scss/labels";
// @import "bootstrap/scss/badges";
// @import "bootstrap/scss/jumbotron";
// @import "bootstrap/scss/thumbnails";
// @import "bootstrap/scss/alerts";
// @import "bootstrap/scss/progress-bars";
// @import "bootstrap/scss/media";
// @import "bootstrap/scss/list-group";
// @import "bootstrap/scss/panels";
// @import "bootstrap/scss/responsive-embed";
// @import "bootstrap/scss/wells";
// @import "bootstrap/scss/close";

// // Components w/ JavaScript
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";

// // Utility classes
// @import "bootstrap/scss/utilities";
// @import "bootstrap/scss/responsive-utilities";

//extra css
@import "css";
@import "event";
@import "event-form";
@import "forum";
@import "login";
@import "hero";
@import "grid-list";
